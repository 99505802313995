export const appPages = {
  dashboard: ['/dashboard'],
  income: ['/allincome', '/incomebytype', '/gemiincome'],
  expenses: ['/allexpenses', 'expensesbytype'],
  unSub: ['/establishments', '/regdelgrouped', '/kadgrouped'],
  demography: ['/demography'],
  gemiCases: ['/gemicases'],
  geography: ['/geography'],
  terms: ['/TermConditions'],
  privacy: ['/PrivacyPolicy'],
};

export const securePages = [
  '/incomebytype',
  '/allincome',
  '/login',
  '/allexpenses',
  '/dashboard',
  '/demography',
  '/establishments',
  '/regdelgrouped',
  '/expensesbytype',
  '/gemicases',
  '/gemiincome',
  '/geography',
  '/PrivacyPolicy',
  '/TermsConditions',
  '/kadgrouped',
];

const publicPaths = process.env.publicPaths;

export const hasPublicPath = (property) => {
  // Get paths for the specific property
  const propertyPaths = appPages[property] || [];

  // Check if any public path exists in propertyPaths array
  return publicPaths.some((path) => propertyPaths.includes(path));
};

export const specificPath = (path) => publicPaths.includes(path);
